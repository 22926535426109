<template>
    <div class="block-contacts">
        <StackRouterHeaderBar :title="$translate('BLOCK_FRIEND')" :show-title="true" />
        <div class="title" v-html="$translate('BLOCK_CONTACT')" />
        <div class="sub-title">해당되는 회원과는 서로 소개되지 않습니다!</div>
        <div class="guide">
            <div class="flex-row">
                <div class="dot m-r-6" />
                <p class="text" @click="openConsent('privacy')">
                    모든 연락처는 차단 목적으로만 사용되며, 절대 공개되지 않습니다.
                    <span class="privacy">(개인정보 처리 방침 보기)</span>
                </p>
            </div>
        </div>
        <div class="section">
            <div class="section-title" v-html="`차단한 연락처`" />
            <div class="block-item add" @click="onClickRegistration">
                <i class="material-icons m-r-4">add</i>
                <span class="c-white">{{ $translate('REGISTER_CONTACTS') }}</span>
            </div>
            <div class="block-item">
                <span class="f-15" v-html="`차단된 연락처`" />
                <span class="f-15 spoqa-f-bold">{{ contactsLength }}개</span>
            </div>
        </div>
    </div>
</template>

<script>
import StackRouterHeaderBar from '../../components/app/StackRouterHeaderBar.vue'
import userService from '@/services/user'

export default {
    name: 'BlockContactsPage',
    components: { StackRouterHeaderBar },
    data: () => ({}),
    watch: {
        contacts(newContacts, oldContacts) {
            if (newContacts.length > 0) {
                this.registerContacts(
                    newContacts.map(c => {
                        if ((c.phoneNumbers[0] || {}).number) {
                            return { phone_number: c.phoneNumbers[0].number }
                        }
                    })
                )
            }
        },
    },
    computed: {
        contacts() {
            return this.$store.getters.contacts || []
        },
        registeredContactsLength() {
            return this.$store.getters.registeredContactsLength || 0
        },
        me() {
            return this.$store.getters.me
        },
        contactsLength() {
            if (this.registeredContactsLength > 0) {
                return this.registeredContactsLength
            }

            return this.contacts.length
        },
    },
    methods: {
        openConsent(termType) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://sm-api.vanillabridge.com/api/users/terms?term_type=${termType}`,
                // value: `${axios.defaults.baseURL}users/terms?term_type=${type}`,
            })
        },
        onClickRegistration() {
            this.$nativeBridge.postMessage({
                action: 'getContactList',
            })
        },
        async registerContacts(contacts) {
            try {
                await userService.registerContacts(this.me.id, { contacts })
                this.$toast.success('주소록에 있는 모든 연락처가 등록되었어요')
                this.$store.dispatch('getRegisteredContactsLength')
            } catch (e) {
                console.error(e)
            }
        },
    },
    mounted() {
        this.$store.dispatch('getRegisteredContactsLength')
    },
}
</script>

<style lang="scss" scoped>
.block-contacts {
    .blocked-contacts {
        padding: 10px 16px;
        border-radius: 10px;
        background-color: $grey-02;
        display: inline-flex;
        align-items: center;

        .material-icons {
            font-size: 20px;
            margin-right: 12px;
        }

        .text {
            margin-right: 4px;
            font-size: 15px;
            color: $grey-08;
        }

        .count {
            font-size: 16px;
            color: $blue-primary;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100vw;
        border-top: solid 1px $grey-02;
        padding: 16px;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
.privacy {
    text-decoration: underline;
    color: $blue-like;
}
</style>
